import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Global assets
import SVGESOPQU from 'assets/icons/esopqu.svg';

// Local components
import CurrencyDropdown from './CurrencyDropdown';
import LanguageDropdown from './LanguageDropdown';
import AccountDropdown from './AccountDropdown';

// Local styles
import styles from './headerBar.module.scss';

const HeaderBar = props => {
	const navigate = useNavigate();
	const [isScrolled, setIsScrolled] = useState(false);
	const isLoggedIn = useSelector(({reducerUser}) => !!reducerUser.userId);
	// Methods
	const scrollListener = () => {
		setIsScrolled(window.scrollY > 24);
	};
	// Hooks
	useEffect(() => {
		window.addEventListener('scroll', scrollListener);
		return () => window.removeEventListener('scroll', scrollListener);
	}, []);
	// Render
	return (
		<div
			className={clsx(props.className, styles.HeaderBar)}
			id="header-bar">
			<div className={clsx(
				styles.HeaderBar__background,
				{[styles['HeaderBar__background--scrolled']]: isScrolled},
			)} />
			<img
				alt="Icon"
				src={SVGESOPQU}
				onClick={() => navigate('/overview')}
				className={clsx(styles.HeaderBar__webAppIcon, 'touchable-opacity')} />
			<div className={styles.HeaderBar__rightActionBar}>
				{isLoggedIn && <CurrencyDropdown />}
				<LanguageDropdown className={styles.HeaderBar__dropdownGap} />
				{isLoggedIn && <AccountDropdown className={styles.HeaderBar__dropdownGap} />}
			</div>
		</div>
	);
};

HeaderBar.defaultProps = {
};

HeaderBar.propTypes = {
	className: PropTypes.string,
};

export default HeaderBar;
