import {useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';

// Global components
import Dropdown from 'components/core/Dropdown';
import AccountDetailModal from './AccountDetailModal';

// Global utils
import AuthUtils from 'utils/auth';
import analytics from 'utils/analytics';

// Local styles
import styles from './accountDropdown.module.scss';

// Local components
import EditPasswordModal from './EditPasswordModal';

const AccountDropdown = props => {
	const navigate = useNavigate();
	const editPasswordRef = useRef(null);
	const accountDetailRef = useRef(null);
	const userName = useSelector(({reducerUser}) => reducerUser.userName);
	const {t} = useTranslation('translation', {keyPrefix: 'dropdowns'});
	// Constants
	const accountActSupported = [
		{
			id: 'accountDetail',
			longLabel: (
				<>
					<FontAwesomeIcon
						icon={solid('user')}
						className={styles.AccountDropdown__dropdown__actionIcon} />
					{t('detail')}
				</>
			),
		},
		{
			id: 'editPassword',
			longLabel: (
				<>
					<FontAwesomeIcon
						icon={solid('pen')}
						className={styles.AccountDropdown__dropdown__actionIcon} />
					{t('editPassword')}
				</>
			),
		},
		{
			id: 'signout',
			longLabel: (
				<>
					<FontAwesomeIcon
						icon={solid('arrow-right-from-bracket')}
						className={styles.AccountDropdown__dropdown__actionIcon} />
					{t('signout')}
				</>
			),
		},
	];
	// Event handler methods
	const onPressUserActionDropdown = (_, {id}) => {
		switch (id) {
		case 'accountDetail':
			accountDetailRef.current?.open();
			break;
		case 'editPassword':
			analytics.logEvent('click_header_account_edit_password');
			editPasswordRef.current?.open?.();
			break;
		case 'signout':
			analytics.logEvent('click_header_account_signout');
			AuthUtils.revokeCurrentSession().catch(() => null).finally(() => {
				navigate('/auth', {replace: true});
			});
			break;
		default:
		}
	};
	// Render
	return (
		<>
			<EditPasswordModal ref={editPasswordRef} />
			<AccountDetailModal ref={accountDetailRef} />
			<Dropdown
				data={accountActSupported}
				dropdownHeadLabel={t('account')}
				onChange={onPressUserActionDropdown}
				className={clsx(styles.AccountDropdown, props.className)}
				mainLabelClassName={styles.AccountDropdown__label}
				dropdownClassName={styles.AccountDropdown__dropdown}
				onClickMainLabel={() => analytics.logEvent('click_header_account_pill')}
				customRenderMainLabel={() => (
					<>
						<div className={styles.AccountDropdown__label__initial}>
							{userName?.[0]}
						</div>
						<p>{userName?.split(' ')?.[0]}</p>
					</>
				)} />
		</>
	);
};

AccountDropdown.defaultProps = {
};

AccountDropdown.propTypes = {
	className: PropTypes.string,
};

export default AccountDropdown;
