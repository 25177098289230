import BugsnagPluginReact from '@bugsnag/plugin-react';
import Bugsnag from '@bugsnag/js';
import get from 'lodash/get';

// Global utils
import {store} from 'store';

import {version} from '../../package.json';

class BugsnagClass {
	constructor() {
		this.bugsnagEnabled =
		  !!(process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUGSNAG_APIKEY);
		this.initialize();
	}

	setBugsnagObjectMetadata(report, errObj) {
		Object.keys(errObj).forEach(key => {
			report.addMetadata('metadata', key, errObj[key]);
		});
	}

	initialize() {
		if (this.bugsnagEnabled) {
			this.Bugsnag = Bugsnag.start({
				apiKey: process.env.REACT_APP_BUGSNAG_APIKEY,
				autoBreadcrumbs: true,
				plugins: [new BugsnagPluginReact()],
				releaseStage: process.env.REACT_APP_RELEASE_STAGE,
				appVersion: version,
				onError: event => {
				  const {userId, userName, organizationCode} = store.getState()?.reducerUser || {};
				  event.setUser(userId, '', userName);
					event.addMetadata('user', 'organizationCode', organizationCode);
				},
			});
		}
	}

	error(err, severity = 'warning') {
		if (this.Bugsnag) {
			if (get(err, 'message') === 'Network Error') {
				severity = 'warning';
			}
			Bugsnag.notify(err, report => {
				if (get(err, 'response.config')) {
					const {headers, method, params, url} = err.response.config;
					report.context =
						err.message === 'Network Error'
							? `Network Error (${url})`
							: `Request failed with status code ${err?.response?.status} (${url})`;
					delete headers?.Authorization;
					this.setBugsnagObjectMetadata(report, {
						headers,
						params,
						responseData: err?.response?.data,
						status: err?.response?.status,
						method,
						url,
					});
				} else if (err.graphql) {
					report.context = `GQL${
						err.graphql?.path ? ` | Path: ${err.graphql.path.join(', ')}` : ''
					}${
						err.graphql?.extensions?.code
							? ` | Code: ${err.graphql.extensions.code}`
							: ''
					}`;
					this.setBugsnagObjectMetadata(report, err.graphql);
				} else {
					this.setBugsnagObjectMetadata(report, {metadata: JSON.stringify(err)}, 'metadata');
				}
				// ["info", "warning", "error"]
				report.severity = severity;
				err.customContext && (report.context = err.customContext);
			});
		}
	}
}

export default new BugsnagClass();
