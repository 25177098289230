import {useState, useEffect, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Global components
import Card from 'components/core/Card';

// Global utils
import {cleanPathname} from 'utils/index';
import analytics from 'utils/analytics';

// Global hooks
import useDelayUnmount from 'hooks/useDelayedUnmount';

// Local styles
import styles from './navigationBar.module.scss';

// Constants
const navigationItems = [
	{
		label: 'Overview',
		route: '/overview',
	},
	{
		label: 'Granted',
		route: '/granted',
	},
	{
		label: 'FAQ',
		route: '/faq',
	},
];

const calculateActiveBgLeftPos = index => 100 / navigationItems.length * index;

const getInitialActiveBgLeftPos = () => {
	let activeIndex = navigationItems.findIndex(({route}) => window.location.pathname.includes(route));
	activeIndex = activeIndex === -1 ? 0 : activeIndex;
	return {
		position: calculateActiveBgLeftPos(activeIndex),
		activeIndex,
	};
};

const getNewNavigationItemsArr = (prevData, activeIndex) => {
	return prevData.reduce((previousValue, currentValue, currentIndex) => {
		return [
			...previousValue,
			{...currentValue, active: currentIndex === activeIndex},
		];
	}, []);
};

const NavigationBar = props => {
	const {position, activeIndex} = getInitialActiveBgLeftPos();
	const [navItemsState, updateNavItem] = useState(getNewNavigationItemsArr(navigationItems, activeIndex));
	const [activeBgLeftPos, updateBgLeftPos] = useState(position);
	const previousRouteRef = useRef(null);
	const location = useLocation();
	const navigate = useNavigate();
	const pathname = cleanPathname(location.pathname);
	const barVisible = !props.excludeNavBarRoutes.includes(pathname);
	const mountBar = useDelayUnmount(barVisible, 250);
	// Methods
	const animateTransitionActive = index => {
		activeBgLeftPos !== null && updateBgLeftPos(calculateActiveBgLeftPos(index));
		updateNavItem(getNewNavigationItemsArr(navItemsState, index));
	};
	// Event handler methods
	const onClickNavItem = index => {
		animateTransitionActive(index);
		navigate(navItemsState[index].route);
		analytics.logEvent(`click_nav_bar_${navItemsState[index].label.toLowerCase()}`);
	};
	// Hooks
	useEffect(() => {
		if (previousRouteRef.current) {
			navItemsState.forEach(({route}, index) => {
				if (route === pathname) {
					animateTransitionActive(index);
				}
			});
		}
		previousRouteRef.current = pathname;
	}, [pathname]);
	// Render
	return (
		mountBar && (
			<div
				id="footer-bar">
				<Card
					className={clsx(styles.NavigationBar, 'disable-text-select', styles[`Animation--${barVisible ? 'fadeIn' : 'fadeOut'}`])}
					withShadow>
					<div className={styles.NavigationBar__bgSliderContainer}>
						<div
							className={styles.NavigationBar__activeLinkBg}
							style={{
								left: `${activeBgLeftPos}%`,
							}} />
					</div>
					<ul className={styles.NavigationBar__linkContainer}>
						{navItemsState.map((link, i) => (
							<li
								key={i}
								id={`bottom-nav-${link.label.toLowerCase()}`}
								className={clsx({
									[styles['NavigationBar__linkContainer--active']]: link.active,
								})}
								onClick={() => onClickNavItem(i)}>{link.label}</li>
						))}
					</ul>
				</Card>
			</div>
		)
	);
};

NavigationBar.defaultProps = {
	excludeNavBarRoutes: [],
};

NavigationBar.propTypes = {
	excludeNavBarRoutes: PropTypes.array,
};

export default NavigationBar;
