import ReactDOM from 'react-dom';
import {useState, forwardRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {regular} from '@fortawesome/fontawesome-svg-core/import.macro';

// Global hooks
import useDelayUnmount from 'hooks/useDelayedUnmount';

// Local styles
import styles from './modal.module.scss';

const Modal = forwardRef((props, ref) => {
	const [modalOpen, setModalOpen] = useState(false);
	const mountModal = useDelayUnmount(modalOpen, 250);
	// Methods
	const setRef = () => {
		const refObject = {
			open: () => setModalOpen(true),
			close: () => setModalOpen(false),
		};
		if (ref && ref.hasOwnProperty('current')) {
			ref.current = refObject;
		} else if (typeof ref === 'function') {
			ref(refObject);
		}
	};
	// Event handler methods
	const onPressBackdrop = event => {
		if ([styles.Modal, styles.Modal__cardContainer].includes(event.target.className)) {
			setModalOpen(false);
		}
	};
	// Hooks
	useEffect(() => setRef(), []);
	useEffect(() => {
		!mountModal && props.onClosed();
	}, [mountModal]);
	// Render
	return ReactDOM.createPortal(
		<>
			{mountModal && (
				<div
					className={clsx(styles.Modal, styles[`Animation--${modalOpen ? 'fadeIn' : 'fadeOut'}`])}
					onClick={onPressBackdrop}>
					<div className={styles.Modal__cardContainer}>
						<div
							className={clsx(props.className, styles.Modal__card, styles[`Animation--${modalOpen ? 'slideIn' : 'slideOut'}`])}>
							<div className={clsx(
								props.headerClassName,
								styles.Modal__header,
								{[styles.Modal__header__custom]: typeof props.header !== 'string'},
							)}>
								{props.header}
								<button
									onClick={() => setModalOpen(false)}
									className={styles.Modal__header__closeButton}>
									<FontAwesomeIcon icon={regular('xmark')} />
								</button>
							</div>
							<div className={clsx(
								props.contentClassName,
								styles.Modal__content,
								{[styles.Modal__content__borderBottom]: props.children},
							)}>
								{props.children}
							</div>
							{props.footer && (
								<div className={clsx(props.footerClassName, styles.Modal__footer)}>
									{props.footer}
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>,
		document.getElementById('root'),
	);
});

Modal.defaultProps = {
	onClosed: () => null,
};

Modal.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	headerClassName: PropTypes.string,
	footerClassName: PropTypes.string,
	contentClassName: PropTypes.string,
	header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	onClosed: PropTypes.func,
};

export default Modal;
