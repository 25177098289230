import React, {useState, useRef, forwardRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';

// Global components
import Card from 'components/core/Card';

// Global utils
import {passingProps} from 'utils/index';

// Local styles
import styles from './input.module.scss';
import {AnimatePresence, motion} from 'framer-motion';

const Input = forwardRef((props, ref) => {
	if (!ref) ref = useRef(null);
	const blacklistedProps = [
		'inputTitle', 'className', 'error', 'value',
		'renderClearButton', 'renderLeft', 'faLeftIcon', 'faRightIcon',
		'onChangeText', 'onFocusChange', 'onFocus', 'onBlur', 'onKeyPress', 'onPressEnter',
		'onClickFaLeftIcon', 'onClickFaRightIcon',
	];

	const [isFilled, setIsFilled] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [showClearButton, setShowClearButton] = useState(false);

	const focusHandler = () => {
		const focusState = document.activeElement === ref.current;
		setIsFocused(focusState);
		const shouldShowClear = props.renderClearButton && focusState && ref.current?.value?.length > 0;
		setTimeout(() => { // Delay to allow the clear button to be pressed before hiding it
			props.onFocusChange?.(focusState);
			setShowClearButton(shouldShowClear);
		}, ('ontouchstart' in window) ? 10 : 150);
	};

	return (
		<div className={clsx(styles.Input, props.className)}>
			<div className={styles.Input__title}>{props.inputTitle}</div>
			<Card
				className={clsx(
					styles.Input__inputField,
					{
						[styles['Input__inputField--focused']]: isFocused,
						[styles['Input__inputField--disabled']]: props.disabled,
						[styles['Input__inputField--error']]: props.error,
					},
				)}
				onClick={() => ref.current?.focus()}>
				{/* renderLeft props has higher render priority */}
				{props.renderLeft || props.faLeftIcon ? (
					<div
						className={styles.Input__inputField__leftRender}>
						{
							props.renderLeft
								? props.renderLeft
								: (
									<FontAwesomeIcon
										icon={props.faLeftIcon}
										className={clsx(
											styles.Input__inputField__leftRender__icon,
											{[styles['Input__inputField__leftRender__icon--black']]: isFocused || isFilled},
										)}
										onClick={props.onClickFaLeftIcon} />
								)
						}
					</div>
				) : null}
				<input
					className={clsx(
						styles.Input__inputField__input,
						{[styles['Input__inputField__input--rightPadding']]: props.faRightIcon || showClearButton},
					)}
					onChange={e => {
						focusHandler();
						setIsFilled(!!e.target.value);
						props.onChangeText?.(e.target.value);
					}}
					onFocus={e => {
						focusHandler();
						props.onFocus?.(e);
					}}
					onBlur={e => {
						focusHandler();
						props.onBlur?.(e);
					}}
					onKeyPress={e => {
						if (e.key === 'Enter') {
							// Blur input to close keyboard on enter pressed
							// this will fix iOS keyboard offset that hides error toasts even it's position is fixed
							e?.target.blur?.();
							props.onPressEnter?.(e);
						}
						props.onKeyPress?.(e);
					}}
					ref={ref}
					value={props.value}
					{...passingProps(props, null, blacklistedProps)}
				/>
				{/* faRightIcon will override clear button */}
				{props.faRightIcon || showClearButton ? (
					<FontAwesomeIcon
						icon={props.faRightIcon || solid('times-circle')}
						onClick={() => {
							if (props.faRightIcon) {
								props.onClickFaRightIcon();
							} else if (ref?.current) {
								ref.current.value = '';
								props.onChangeText('');
								ref.current?.focus();
							}
						}}
						className={clsx(styles.Input__inputField__clearButton, 'touchable-opacity')}
					/>
				) : null}
			</Card>
			<AnimatePresence>
				{props.error && (
					<motion.p
						initial={{height: 0}}
						animate={{height: 'auto'}}
						exit={{height: 0}}
						transition={{duration: 0.25}}
						className={styles.Input__error}>{props.error}</motion.p>
				)}
			</AnimatePresence>
		</div>
	);
});

Input.defaultProps = {
	inputTitle: 'Title',
	renderClearButton: false,
	disabled: false,
	onClickFaLeftIcon: () => null,
	onClickFaRightIcon: () => null,
};

Input.propTypes = {
	inputTitle: PropTypes.string,
	renderClearButton: PropTypes.bool,
	renderLeft: PropTypes.element,
	disabled: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	error: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool,
	]),
	faLeftIcon: PropTypes.object,
	faRightIcon: PropTypes.object,
	onChangeText: PropTypes.func,
	onFocusChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onKeyPress: PropTypes.func,
	onPressEnter: PropTypes.func,
	onClickFaLeftIcon: PropTypes.func,
	onClickFaRightIcon: PropTypes.func,
	className: PropTypes.string,
};

export default Input;
