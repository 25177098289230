import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {motion, AnimatePresence} from 'framer-motion';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';

// Local styles
import styles from './button.module.scss';

const Button = props => {
	// Render
	return (
		<button
			onClick={props.onClick}
			disabled={props.disabled}
			className={clsx(
				{'touchable-opacity': !props.disabled},
				'disable-text-select',
				styles.Button,
				styles['Button__' + props.mode],
				styles['Button__size--' + props.size],
				props.className,
			)}>
			<div className={clsx(styles.Button__content, props.contentClassName)}>
				{typeof props.label === 'string' ? (
					props.label
				) : (
					props.children
				)}
			</div>
			<AnimatePresence>
				{props.loading && (
					<motion.div
						initial={{scale: 0, width: 0}}
						animate={{scale: 1, width: 'auto'}}
						exit={{scale: 0, width: 0}}
						transition={{duration: 0.25}}
					>
						<FontAwesomeIcon
							className={styles.Button__loading}
							icon={solid('circle-notch')}
							spin />
					</motion.div>
				)}
			</AnimatePresence>
		</button>
	);
};

Button.defaultProps = {
	mode: 'contained',
	size: 'large',
	loading: false,
	disabled: false,
	onClick: () => null,
};

Button.propTypes = {
	label: PropTypes.string,
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	mode: PropTypes.oneOf(['outlined', 'contained', 'ghost']),
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	className: PropTypes.string,
	contentClassName: PropTypes.string,
	children: PropTypes.node,
};

export default Button;
