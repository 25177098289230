import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Outlet, Routes, Route, useLocation} from 'react-router-dom';
import {AnimatePresence, motion} from 'framer-motion';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Global utils
import {fadeInOutMotionConfig} from 'utils/animation';

// Local styles
import styles from './transitionRoute.module.scss';

const Wrapper = () => {
	const [hasHeader, setHasHeader] = useState(false);
	const [hasFooter, setHasFooter] = useState(false);
	useLayoutEffect(() => {
		// Timeout is used to force the header and footer state to be rerendered first before we detect for it's existence
		setTimeout(() => {
			// Check if the current route has header, append padding-top class if exists
			const headerExists = document.getElementById('header-bar') !== null;
			setHasHeader(headerExists);
			// Check if the current route has footer, append padding-bottom class if exists
			const footerExists = document.getElementById('footer-bar') !== null;
			setHasFooter(footerExists);
		}, 1);
	}, []);
	// Render
	return (
		<motion.div
			{...fadeInOutMotionConfig}
			className={clsx(styles.TransitionRoute, {
				[styles.TransitionRoute__header]: hasHeader,
				[styles.TransitionRoute__footer]: hasFooter,
			})}
		>
			<Outlet />
		</motion.div>
	);
};

const TransitionRoute = ({children}) => {
	const location = useLocation();
	const isRouteDefined = children.some(child => child.props.path === location.pathname);

	useEffect(() => {
		if ('scrollRestoration' in history) {
			// Prevent browser from restoring scroll positions when using back/forward buttons
			// https://stackoverflow.com/questions/48308390/javascript-prevent-browser-from-restoring-scroll-positions-when-using-the-back
			// Back off, browser, I got this...
			history.scrollRestoration = 'manual';
		}
	}, []);

	// Disable transition when the specified route or url is not defined in routes
	// The react router's unspecified route redirect will fire many times (possibly hundred) when it's animated and may cause crash on some iOS
	const AnimatePresenceWrapper = isRouteDefined
		? AnimatePresence
		: ({children}) =><div>{children}</div>;

	// Render
	return (
		<AnimatePresenceWrapper
			exitBeforeEnter
			onExitComplete={() => {
				// Reset scroll position to top when exiting a route
				window.scrollTo(0, 0);
			}}>
			<Routes
				location={location}
				key={location.pathname}>
				<Route element={<Wrapper />}>
					{children}
				</Route>
			</Routes>
		</AnimatePresenceWrapper>
	);
};

TransitionRoute.propTypes = {
	children: PropTypes.node,
};

export default TransitionRoute;
