import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import clsx from 'clsx';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {regular} from '@fortawesome/fontawesome-svg-core/import.macro';

// Global components
import Dropdown from 'components/core/Dropdown';

// Global assets
import SVGIndonesia from 'assets/icons/country/indonesia.svg';
import SVGAmerica from 'assets/icons/country/america.svg';

// Redux
import {setCurrency} from 'store/actions';

// Local styles
import styles from './currencyDropdown.module.scss';
import analytics from '../../../../utils/analytics';

const currencySupported = [
	{
		id: 'USD',
		shortLabel: (
			<span className={styles.currencyShort}>
				<img src={SVGAmerica} />
				{'USD'}
			</span>
		),
		longLabel: (
			<span className={styles.currencyLong}>
				<img src={SVGAmerica} />
				{'USD ($)'}
			</span>
		),
	},
	{
		id: 'IDR',
		shortLabel: (
			<span className={styles.currencyShort}>
				<img src={SVGIndonesia} />
				{'IDR'}
			</span>
		),
		longLabel: (
			<span className={styles.currencyLong}>
				<img src={SVGIndonesia} />
				{'IDR (Rp)'}
			</span>
		),
	},
];

const CurrencyDropdown = props => {
	const {t} = useTranslation('translation', {keyPrefix: 'dropdowns'});
	const activeCurrencyId = useSelector(({reducerCommon}) => reducerCommon.currency);
	const dispatch = useDispatch();
	useEffect(() => {
		// Making sure the active currency is using one of the supported currency. If not, set the first one as active currency.
		if (!currencySupported.some(currency => currency.id === activeCurrencyId)) {
			dispatch(setCurrency(currencySupported[0].id));
		}
	}, []);
	// Render
	return (
		<Dropdown
			data={currencySupported}
			dropdownHeadLabel={t('currency')}
			activeDropdownId={activeCurrencyId}
			dropdownClassName={styles.CurrencyDropdown__dropdown}
			className={clsx(styles.CurrencyDropdown, props.className)}
			onChange={async (index, data) => {
				analytics.setUserProperties(); // Update analytics properties when currency changed
				analytics.logEvent('change_currency', {currency: data.id}); // Override analytics currency to prevent sending stale user properties data
				dispatch(setCurrency(data?.id));
			}}
			mainLabelClassName={styles.CurrencyDropdown__mainLabelContainer}
			onClickMainLabel={() => analytics.logEvent('click_header_currency_pill')}
			customRenderMainLabel={data => (
				<>
					{data?.shortLabel}
					<FontAwesomeIcon
						icon={regular('chevron-down')}
						className={styles.CurrencyDropdown__chevronIcon} />
				</>
			)} />
	);
};

CurrencyDropdown.defaultProps = {
};

CurrencyDropdown.propTypes = {
	className: PropTypes.string,
};

export default CurrencyDropdown;
