import React from 'react';
import ReactDOM from 'react-dom';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';
import Bugsnag from '@bugsnag/js';

import App from './App';

// Global utils
import 'utils/i18n';
import crashLogger from 'utils/logger';

// Redux
import {store, persistor} from 'store';

import reportWebVitals from './reportWebVitals';

import './index.scss';

const ErrorBoundary = crashLogger.bugsnagEnabled
	? Bugsnag.getPlugin('react').createErrorBoundary(React)
	: React.Fragment;

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<Provider store={store}>
				<PersistGate
					loading={null}
					persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
