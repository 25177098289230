import {SET_USER_DATA, CLEAR_USER_DATA, SET_CURRENCY} from './actionTypes';

export const setUserData = payload => {
	return {
		type: SET_USER_DATA,
		data: payload,
	};
};

export const clearUserData = () => {
	return {
		type: CLEAR_USER_DATA,
	};
};

export const setCurrency = payload => {
	return {
		type: SET_CURRENCY,
		data: payload,
	};
};
