import {useEffect} from 'react';
import {useLocation} from 'react-router';

// Global utils
import analytics from 'utils/analytics';

export default function PageViewAnalytics() {
	const {pathname} = useLocation();
	// Hooks
	useEffect(() => {
		analytics.initialize();
	}, []);
	useEffect(() => {
		analytics.pageview();
	}, [pathname]);
	// Render
	return null;
}
