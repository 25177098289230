import {Toaster as T, toast as toastLib} from 'react-hot-toast';
import PropTypes from 'prop-types';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {solid, regular} from '@fortawesome/fontawesome-svg-core/import.macro';

// Local styles
import styles from './toaster.module.scss';

// Custom ToastBar component to show description and render close button
const ToastBar = props => (
	<div
		className={styles.Toaster__toast__content}
		onClick={() => props?.onClick?.(props.id)}>
		{props.icon ? (
			<div className={styles.Toaster__toast__content__leftIcon}>
				{props.icon}
			</div>
		) : null}
		<div className={styles.Toaster__toast__content__middle}>
			<div className={styles.Toaster__toast__content__middle__title}>{props.title}</div>
			{props.description ?
				<div className={styles.Toaster__toast__content__middle__description}>{props.description}</div>
				: null
			}
		</div>
		<div
			className={styles.Toaster__toast__content__closeButton}
			onClick={e => {
				e.stopPropagation();
				toastLib.dismiss(props.id);
			}}>
			<FontAwesomeIcon icon={regular('xmark')} />
		</div>
	</div>
);

ToastBar.propTypes = {
	id: PropTypes.string,
	icon: PropTypes.element,
	title: PropTypes.string,
	description: PropTypes.string,
	onClick: PropTypes.func,
};

export const toast = { // Collection of custom ToastBar caller functions for easy access
	success: (title, description, callback) => {
		toastLib(({id}) => (
			<ToastBar
				id={id}
				title={title}
				description={description}
				icon={(
					<FontAwesomeIcon
						icon={solid('circle-check')}
						color={styles.lime} />
				)}
				onClick={callback}
			/>
		));
	},
	error: (title, description, callback) => {
		toastLib(({id}) => (
			<ToastBar
				id={id}
				title={title}
				description={description}
				icon={(
					<FontAwesomeIcon
						icon={solid('triangle-exclamation')}
						color={styles.ketchup} />
				)}
				onClick={callback}
			/>
		));
	},
	dismiss: id => {
		toastLib.dismiss(id);
	},
};

const Toaster = () => {
	// Render
	return (
		<T
			toastOptions={{className: styles.Toaster__toast}}
			containerClassName={styles.Toaster} />
	); // Override default toast card's style
};

export default Toaster;
