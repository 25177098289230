import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';

// Global Components
import InputPassword from 'components/core/Input/InputPassword';

// Local styles
import styles from './passwordConfirmationInput.module.scss';

const validations = [
	{key: 'MIN_8_CHARS', validate: (password = '') => password.length >= 8},
	{key: 'MIN_1_NUM_OR_SYM', validate: (password = '') => (/\W|\d|_/g).test(password)},
	{key: 'MIN_1_CAPITAL', validate: (password = '') => (/[A-Z]/g).test(password)},
	{key: 'PASSWORD_MATCH', validate: (password = '', confirmPassword = '') => password !== '' && password === confirmPassword},
];

const PasswordConfirmationInput = props => {
	const {t} = useTranslation('translation', {keyPrefix: 'authPage.passwordSetup.setupForm'});
	const [value, setValue] = useState({
		password: '',
		confirmPassword: '',
		errors: validations.map(e => e.key),
	});
	// Methods
	const validatePassword = ({password, confirmPassword}) => {
		const errors = [];
		validations.forEach(validation => {
			if (!validation.validate(password, confirmPassword)) {
				errors.push(validation.key);
			}
		});
		return errors;
	};
	// Event handler methods
	const onChangeText = (name, newValue) => {
		const newValueObj = {
			...value,
			[name]: newValue,
		};
		const errors = validatePassword(newValueObj);
		setValue({...newValueObj, errors});
		props.onChangePassword({...newValueObj, errors});
	};
	// Render
	return (
		<>
			<InputPassword
				className={styles.PasswordConfirmationInput__formGap}
				inputTitle={t(props.isChangePassword ? 'newPassword' : 'password')}
				placeholder={t(props.isChangePassword ? 'newPasswordPlaceholder' : 'passwordPlaceholder')}
				onChangeText={val => onChangeText('password', val)}
				onPressEnter={props.onPressEnter}
				autoComplete="new-password"
			/>
			<InputPassword
				className={styles.PasswordConfirmationInput__formGap}
				inputTitle={t(props.isChangePassword ? 'confirmNewPassword' : 'confirmPassword')}
				placeholder={t(props.isChangePassword ? 'confirmNewPasswordPlaceholder' : 'confirmPasswordPlaceholder')}
				onChangeText={val => onChangeText('confirmPassword', val)}
				onPressEnter={props.onPressEnter}
				autoComplete="new-password"
			/>
			<div className={clsx(
				styles.PasswordConfirmationInput__validationContainer,
				styles.PasswordConfirmationInput__formGap,
			)}>
				{validations.map(validation => {
					return (
						<div
							key={validation.key}
							className={clsx(
								styles.PasswordConfirmationInput__validationContainer__row,
								{[styles['PasswordConfirmationInput__validationContainer__row--valid']]: !value.errors.includes(validation.key)},
							)}>
							<FontAwesomeIcon icon={solid('circle-check')} />
							{t('validation.' + validation.key)}
						</div>
					);
				})}
			</div>
		</>
	);
};

PasswordConfirmationInput.defaultProps = {
	isChangePassword: false,
	onChangePassword: () => null,
	onPressEnter: () => null,
};

PasswordConfirmationInput.propTypes = {
	isChangePassword: PropTypes.bool,
	onChangePassword: PropTypes.func,
	onPressEnter: PropTypes.func,
};

export default PasswordConfirmationInput;
