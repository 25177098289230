// Global utils
import {currencySupported} from 'utils/constants';

// Local Redux
import {SET_CURRENCY} from '../actionTypes';

const initialState = {
	currency: currencySupported[0]?.id || 'USD',
};

const reducersCommon = (state = initialState, {type, data}) => {
	switch (type) {
	case SET_CURRENCY:
	  return {...state, currency: data};
	default:
		return state;
	}
};

export default reducersCommon;
