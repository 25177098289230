export const fadeInOutMotionConfig = {
	initial: 'out',
	animate: 'in',
	exit: 'out',
	variants: {
		in: {opacity: 1},
		out: {opacity: 0},
	},
	transition: {
		duration: 0.25,
	},
};