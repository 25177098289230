import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import clsx from 'clsx';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';

// Global components
import Dropdown from 'components/core/Dropdown';

// Global utils
import analytics from 'utils/analytics';

// Global assets
import SVGIndonesia from 'assets/icons/country/indonesia.svg';
import SVGAmerica from 'assets/icons/country/america.svg';

// Local styles
import styles from './languageDropdown.module.scss';

const languageSupported = [
	{
		id: 'en',
		longLabel: (
			<span className={styles.languageLabel}>
				<img src={SVGAmerica} />
				{'English'}
			</span>
		),
	},
	{
		id: 'id',
		longLabel: (
			<span className={styles.languageLabel}>
				<img src={SVGIndonesia} />
				{'Indonesia'}
			</span>
		),
	},
];

const LanguageDropdown = props => {
	const {t, i18n} = useTranslation('translation', {keyPrefix: 'dropdowns'});
	// Event handler methods
	const onChangeLanguage = (index, data) => {
		analytics.setUserProperties(); // Update analytics properties when currency changed
		analytics.logEvent('change_language', {language: data.id}); // Override analytics currency to prevent sending stale user properties data
		dayjs.locale(data?.id);
		i18n.changeLanguage(data?.id).then(() => {
			Cookies.set('i18n', data?.id);
		});
	};
	// Render
	return (
		<Dropdown
			data={languageSupported}
			onChange={onChangeLanguage}
			activeDropdownId={i18n.language}
			dropdownHeadLabel={t('language')}
			dropdownClassName={styles.LanguageDropdown__dropdown}
			className={clsx(styles.LanguageDropdown, props.className)}
			mainLabelClassName={styles.LanguageDropdown__mainLabelContainer}
			onClickMainLabel={() => analytics.logEvent('click_header_language_pill')}
			customRenderMainLabel={(_, {dropdownActive}) => (
				<>
					<FontAwesomeIcon
						icon={solid('earth')}
						className={clsx(
							styles.LanguageDropdown__globeIcon,
							{[styles['LanguageDropdown__globeIcon--active']]: dropdownActive},
						)} />
				</>
			)} />
	);
};

LanguageDropdown.defaultProps = {
};

LanguageDropdown.propTypes = {
	className: PropTypes.string,
};

export default LanguageDropdown;
