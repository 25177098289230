import {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck} from '@fortawesome/pro-solid-svg-icons';

// Global components
import Card from 'components/core/Card';

// Local styles
import styles from './dropdown.module.scss';

const Dropdown = props => {
	const mainLabelRef = useRef(null);
	const dropdownListRef = useRef(null);
	const [dropdownActive, setActiveStatus] = useState(false);
	const activeDropdownData = props.data.filter(element => {
		return element?.id === props.activeDropdownId;
	})[0];
	// Methods
	const onClickDropdownList = (index, element) => {
		setActiveStatus(false);
		props.onChange(index, element);
	};
	// Hooks
	useEffect(() => {
		const listener = e => {
			const clicksMainLabel = mainLabelRef.current?.contains(e.target);
			const clicksDropdownList = dropdownListRef.current?.contains(e.target);
			if (clicksMainLabel) { // Show or hide dropdown when clicked on main label
				setActiveStatus(!dropdownActive);
			} else if (!clicksDropdownList) { // Hide dropdown when clicked outside of main label and dropdown list
				setActiveStatus(false);
			}
		};
		window.addEventListener('click', listener);
		return () => window.removeEventListener('click', listener);
	}, [dropdownActive]);
	// Render
	return (
		<Card
			onClick={() => {
				setActiveStatus(true);
				props.onClickMainLabel();
			}}
			className={clsx(
				props.className,
				styles.Dropdown,
				{[styles['Dropdown--active']]: dropdownActive},
			)}>
			<div
				ref={mainLabelRef}
				className={clsx(
					props.mainLabelClassName,
					styles.Dropdown__mainLabelContainer,
					'touchable-opacity')}>
				{typeof props.customRenderMainLabel === 'function'
					? props.customRenderMainLabel(activeDropdownData, {dropdownActive})
					: (
						<p className={styles.Dropdown__mainLabelContainer__defaultText}>
							{activeDropdownData?.shortLabel}
						</p>
					)}
			</div>
			<div className={clsx(
				styles.Dropdown__listOuterContainer,
				{[styles['Dropdown--visible']]: dropdownActive},
				props.dropdownClassName,
			)}>
				<Card
					className={styles.Dropdown__listInnerContainer}
					ref={dropdownListRef}
					withShadow>
					{props.dropdownHeadLabel && (
						<span className={clsx(
							props.headLabelClassName,
							styles.Dropdown__listInnerContainer__headLabel,
						)}>
							{props.dropdownHeadLabel}
						</span>
					)}
					{props.data.map((element, index) => (
						<li
							key={index}
							onClick={e => {
								e.stopPropagation();
								onClickDropdownList(index, element);
							}}>
							<p>{element.longLabel}</p>
							{activeDropdownData?.id === element.id && (
								<FontAwesomeIcon
									icon={faCircleCheck}
									className={styles.Dropdown__mainLabelContainer__icon} />
							)}
						</li>
					))}
				</Card>
			</div>
		</Card>
	);
};

Dropdown.defaultProps = {
	data: [],
	onChange: () => null,
	onClickMainLabel: () => null,
};

Dropdown.propTypes = {
	data: PropTypes.array,
	onChange: PropTypes.func,
	onClickMainLabel: PropTypes.func,
	className: PropTypes.string,
	dropdownHeadLabel: PropTypes.string,
	dropdownClassName: PropTypes.string,
	mainLabelClassName: PropTypes.string,
	headLabelClassName: PropTypes.string,
	customRenderMainLabel: PropTypes.func,
	activeDropdownId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Dropdown;
