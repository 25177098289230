import {forwardRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Global utils
import {passingProps} from 'utils/index';

// Local styles
import styles from './card.module.scss';

const blacklistedProps = ['withShadow'];

const Card = forwardRef((props, ref) => {
	return (
		<div
			{...passingProps(props, null, blacklistedProps)}
			ref={ref}
			className={clsx(
				styles.Card,
				{[styles['Card--shadow']]: props.withShadow},
				props.className,
			)}>
			{props.children}
		</div>
	);
});

Card.defaultProps = {
	withShadow: false,
};

Card.propTypes = {
	withShadow: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
};

export default Card;
