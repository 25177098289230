import React, {Suspense, useEffect} from 'react';
import lazy from 'react-lazy-with-preload';
import {Route, BrowserRouter, Navigate} from 'react-router-dom';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import 'dayjs/locale/id';

// Local styles
import './App.scss';

// Global components
import Toaster from 'components/core/Toaster';
import HeaderBar from 'components/others/HeaderBar';
import NavigationBar from 'components/others/NavigationBar';
import TransitionRoute from 'components/others/TransitionRoute';
import RequireAuthRoute from 'components/others/RequireAuthRoute';
import PageViewAnalytics from 'components/others/PageViewAnalytics';

// Global utils
import crashLogger from 'utils/logger';

const importRetry = async (importFn, retries = 2, interval = 1000) => {
	try {
		return await importFn();
	} catch (error) {
		if (retries) {
			await new Promise(resolve => setTimeout(resolve, interval));
			return importRetry(importFn, retries - 1, interval);
		} else {
			crashLogger.error(error);
		}
	}
};

// Pages
const Overview = lazy(() => importRetry(() => import('views/Overview')));
const Granted = lazy(() => importRetry(() => import('views/Granted')));
const GrantedDetail = lazy(() => importRetry(() => import('views/GrantedDetail')));
const FAQPage = lazy(() => importRetry(() => import('views/FAQPage')));
const AuthLogin = lazy(() => importRetry(() => import('views/Auth/Login')));
const AuthPasswordSetup = lazy(() => importRetry(() => import('views/Auth/PasswordSetup')));
const AuthForgotPassword = lazy(() => importRetry(() => import('views/Auth/ForgotPassword')));
const AdminMigrate = lazy(() => importRetry(() => import('views/Admin/Migrate')));

export default function App() {
	// Methods
	const preloadPages = async () => {
		// Only preload pages that user may visit by navigating
		await Overview?.preload?.();
		await Granted?.preload?.();
		await GrantedDetail?.preload?.();
		await FAQPage?.preload?.();
		await AuthLogin?.preload?.();
		await AuthForgotPassword?.preload?.();
	};
	// Hooks
	useEffect(() => {
		dayjs.locale(Cookies.get('i18n') || 'id'); // Set dayjs locale
		preloadPages();
	}, []);
	// Render
	return (
		<BrowserRouter>
			<PageViewAnalytics />
			<Toaster />
			<HeaderBar />
			<Suspense fallback={null}>
				<TransitionRoute>
					<Route
						path="/overview"
						element={(
							<RequireAuthRoute>
								<Overview />
							</RequireAuthRoute>
						)} />
					<Route
						path="/granted"
						element={(
							<RequireAuthRoute>
								<Granted />
							</RequireAuthRoute>
						)} />
					<Route
						path="/granted/:id"
						element={(
							<RequireAuthRoute>
								<GrantedDetail />
							</RequireAuthRoute>
						)} />
					<Route
						path="/faq"
						element={(
							<RequireAuthRoute>
								<FAQPage />
							</RequireAuthRoute>
						)} />
					<Route
						path="/auth"
						element={<AuthLogin />} />
					<Route
						path="/auth/set-password"
						element={<AuthPasswordSetup />} />
					<Route
						path="/auth/forgot-password"
						element={<AuthForgotPassword />} />
					<Route
						path="/admin/migrate"
						element={(
							<RequireAuthRoute>
								<AdminMigrate />
							</RequireAuthRoute>
						)} />
					<Route
						path="*"
						element={(
							<Navigate
								to="/overview"
								replace />
						)} />
				</TransitionRoute>
			</Suspense>
			<NavigationBar excludeNavBarRoutes={['/auth', '/auth/set-password', '/auth/forgot-password', '/auth/forgot-email', '/admin/migrate']} />
		</BrowserRouter>
	);
}
