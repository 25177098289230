import {forwardRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

// Global components
import Modal from 'components/core/Modal';
import Button from 'components/core/Button';
import {toast} from 'components/core/Toaster';
import InputPassword from 'components/core/Input/InputPassword';
import PasswordConfirmationInput from 'components/others/PasswordConfirmationInput';

// Global utils
import {errorCodes} from 'utils/constants';
import {showGeneralErrorToast} from 'utils/index';

// Request utils
import {defaultState} from 'request/index';
import {updatePasswordRequest} from 'request/auth';

// Local styles
import styles from './editPasswordModal.module.scss';
import analytics from 'utils/analytics';

const EditPasswordModal = forwardRef((_, ref) => {
	const {t} = useTranslation('translation', {keyPrefix: 'general.editPasswordModal'});
	const [passwordUpdateState, setPasswordUpdateState] = useState(defaultState);
	const [formValue, setFormValue] = useState({
		oldPassword: {value: '', error: false},
		newPassword: {value: '', errors: [true]}, // error contains list of not valid errors (e.g. min 8 char, min 1 number/symbol, etc)
	});
	// Methods
	const formHasError = () => {
		return formValue.oldPassword.value === '' ||
    !!formValue.oldPassword?.error ||
    formValue.newPassword?.errors?.length > 0;
	};
	const onSubmit = async() => {
		if (formHasError()) return;
		analytics.logEvent('submit_edit_password');
		try {
			const response = await updatePasswordRequest({
				oldPassword: formValue.oldPassword.value,
				newPassword: formValue.newPassword.value,
			}, setPasswordUpdateState);
			if (response.success) {
				analytics.logEvent('edit_password_success');
				toast.success(t('successToast'));
				ref.current?.close?.();
			}
		} catch (e) {
			const errorCode = e?.response?.data?.errorCode;
			if (errorCode === errorCodes.WRONG_EMAIL_OR_PASSWORD) {
				analytics.logEvent('edit_password_old_password_mismatch');
				return setFormValue({
					...formValue,
					oldPassword: {...formValue.oldPassword, error: t('wrongCurrentPassword')},
				});
			}
			showGeneralErrorToast(e);
		}
	};
	// Render
	return (
		<Modal
			ref={ref}
			header={t('title')}
			className={styles.EditPasswordModal}
			footer={(
				<>
					<Button
						className={styles.EditPasswordModal__footer__button}
						label={t('changePassword')}
						size="medium"
						loading={passwordUpdateState.loading}
						disabled={passwordUpdateState.loading || formHasError()}
						onClick={onSubmit}
					/>
				</>
			)}
		>
			<InputPassword
				className={styles.EditPasswordModal__oldPassword}
				inputTitle={t('currentPassword')}
				placeholder={t('currentPasswordPlaceholder')}
				error={formValue.oldPassword.error}
				onChangeText={val => setFormValue({...formValue, oldPassword: {value: val, errors: false}})}
				onPressEnter={onSubmit}
				autoComplete="off"
			/>
			<PasswordConfirmationInput
				isChangePassword
				onChangePassword={e => setFormValue({...formValue, newPassword: {
					value: e.password,
					errors: e.errors,
				}})}
				onPressEnter={onSubmit} />
		</Modal>
	);
});

export default EditPasswordModal;
