import isArray from 'lodash/isArray';
import i18n from 'i18next';

// Global components
import {toast} from 'components/core/Toaster';

// Redux
import {store} from 'store';

export const passingProps = (props, whitelistProps, blacklistProps) => {
	return Object.keys(props).reduce((previousValue, currentValue) => {
		if (isArray(whitelistProps) && whitelistProps.includes(currentValue)) {
			return {...previousValue, [currentValue]: props[currentValue]};
		} else if (
			isArray(blacklistProps) &&
      !blacklistProps.includes(currentValue)
		) {
			return {...previousValue, [currentValue]: props[currentValue]};
		}
		return previousValue;
	}, {});
};

export const roundNumber = (number, decimals) => {
	const parsedNumber = parseFloat(number);
	if (!isNaN(parsedNumber)) {
		return Math.round((parsedNumber + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals);
	} else {
		return number;
	}
};

export const numberFormat = amount => {
	const rounded = roundNumber(amount, 2);
	return rounded
		? rounded.toString()
			.replace(/\./g, ',') // Replace decimal point with comma
			.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
		: 0;
};

export const currencyPrefix = amount => {
	const numberFormatted = numberFormat(amount);
	const currency = store.getState()?.reducerCommon?.currency || 'USD';
	return (currency === 'IDR' ? 'Rp' : '$') + numberFormatted;
};

export const isEmailValid = (value = '') => {
	const regexPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return value.match(regexPattern);
};

export const cleanPathname = (pathname = '') => {
	return pathname.replace(/\/$/, '');
};

export const showGeneralErrorToast = (error, config = {}) => {
	if (config.skip401 && error.response?.status === 401) return;
	toast.error(i18n.t('general.unknownErrorToast'), i18n.t('general.unknownErrorToastDescription'));
};


export const pxToVwMobileMaxed = (pxValue, includePxUnit = false) => {
	const parsedPxValue = parseInt(pxValue);
	const designMobileWidth = parseInt(425);

	const deviceSize = (parsedPxValue * window.innerWidth) / designMobileWidth;


	if (parsedPxValue < 0) {
		const maxResult = Math.max(deviceSize, parsedPxValue);
		return includePxUnit ? `${maxResult}px` : maxResult;
	}
	const minResult = Math.min(deviceSize, parsedPxValue);
	return includePxUnit ? `${minResult}px` : minResult;
};