import {persistStore, persistReducer, createMigrate} from 'redux-persist';
import {createStore, applyMiddleware, compose} from 'redux';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk';

// Reducers
import rootReducer from './reducers/index';

import migrations from './migrations';

const persistConfig = {
	key: 'root',
	version: 0,
	migrate: createMigrate(migrations, {debug: false}),
	storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancer = process.env.NODE_ENV === 'development'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  || compose;
export const store = createStore(
	persistedReducer,
	composeEnhancer(applyMiddleware(ReduxThunk)),
);
export const persistor = persistStore(store);
