export const currencySupported = [
	// https://en.wikipedia.org/wiki/ISO_4217
	{
		id: 'USD',
		prefix: '$',
	},
	{
		id: 'IDR',
		prefix: 'Rp',
	},
];

export const errorCodes = {
	EMAIL_NOT_REGISTERED: 'ESP-101',
	WRONG_EMAIL_OR_PASSWORD: 'ESP-102',
	CODE_SESSION_EXPIRED: 'ESP-103',
	USER_NOT_VERIFIED: 'ESP-104',
};