import {forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';

// Global components
import Modal from 'components/core/Modal';
import Input from 'components/core/Input';

// Local styles
import styles from './accountDetailModal.module.scss';

const AccountDetailModal = forwardRef((props, ref) => {
	const {t} = useTranslation('translation', {keyPrefix: 'headerBar'});
	const {userName, userEmail} = useSelector(({reducerUser}) => reducerUser);
	// Render
	return (
		<Modal
			ref={ref}
			header={t('accountDetailModal.header')}
			footerClassName={styles.AccountDetailModal__footer}
			footer={(
				<>
					<FontAwesomeIcon
						icon={solid('circle-info')}
					/>
					<p>{t('accountDetailModal.footer')}</p>
				</>
			)}>
			<Input
				disabled={true}
				value={userName}
				faLeftIcon={solid('user')}
				inputTitle={t('accountDetailModal.fullName')}
				className={styles.AccountDetailModal__content__inputFullName} />
			<Input
				disabled={true}
				value={userEmail}
				faLeftIcon={solid('envelope')}
				inputTitle={t('accountDetailModal.email')} />
		</Modal>
	);
});

export default AccountDetailModal;
