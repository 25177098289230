import React, {useState} from 'react';

// Fontawesome
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';

// Global components
import Input from 'components/core/Input';

const InputPassword = props => {
	const [buttonVisible, setButtonVisible] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	return (
		<Input
			{...props}
			type={showPassword ? 'text' : 'password'}
			faLeftIcon={solid('lock')}
			faRightIcon={buttonVisible ? showPassword ? solid('eye-slash') : solid('eye') : null}
			onClickFaRightIcon={() => setShowPassword(!showPassword)}
			onFocusChange={setButtonVisible}
		/>
	);
};

export default InputPassword;