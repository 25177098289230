// Global utils
import AuthUtils from 'utils/auth';

// Request utils
import request, {REQUEST_ENV} from 'request/index';

export const authCheckRequest = () => {
	return request({
		method: 'get',
		url: `${REQUEST_ENV.SERVICE_API_HOST}/public/auth/me`,
		headers: {
	    Authorization: `Bearer ${AuthUtils.sessionToken}`,
	  },
	});
};

export const loginUserRequest = (payload, setState) => {
	// payload: {email, password}
	return request({
		method: 'post',
	  url: `${REQUEST_ENV.SERVICE_API_HOST}/public/auth/login`,
	  data: payload,
	}, setState);
};

export const logoutUserRequest = setState => {
	return request({
		method: 'post',
		url: `${REQUEST_ENV.SERVICE_API_HOST}/public/auth/logout`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	}, setState);
};

export const setPasswordRequest = (payload, setState) => {
	// payload: {code, password}
	return request({
		method: 'post',
	  url: `${REQUEST_ENV.SERVICE_API_HOST}/public/auth/password/set`,
	  data: payload,
	}, setState);
};

export const updatePasswordRequest = (payload, setState) => {
	// payload: {oldPassword, newPassword}
	return request({
		method: 'post',
	  url: `${REQUEST_ENV.SERVICE_API_HOST}/public/auth/password/update`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	  data: payload,
	}, setState);
};

export const resetPasswordRequest = (payload, setState) => {
	// payload: {email}
	return request({
		method: 'post',
	  url: `${REQUEST_ENV.SERVICE_API_HOST}/public/auth/password/reset`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	  data: payload,
	}, setState);
};

export const extractAuthCodeRequest = (payload, setState) => {
	// payload: {code}
	return request({
		method: 'post',
	  url: `${REQUEST_ENV.SERVICE_API_HOST}/public/auth/code`,
	  data: payload,
	}, setState);
};
